var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Slot", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "A default Vue slot that can be used instead of passing in an array of object. This allows for greater control of the breadcrumbs"
        ),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-breadcrumb", [
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: "/" } }, [_vm._v("Home")]),
                _c("span", { staticClass: "vs-breadcrum--separator" }, [
                  _vm._v("/"),
                ]),
              ],
              1
            ),
            _c(
              "li",
              [
                _c("router-link", { attrs: { to: "/apps/chat" } }, [
                  _vm._v("Chat"),
                ]),
                _c("span", { staticClass: "vs-breadcrum--separator" }, [
                  _vm._v("/"),
                ]),
              ],
              1
            ),
            _c(
              "li",
              { staticClass: "active", attrs: { "aria-current": "page" } },
              [_vm._v("Infos")]
            ),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-breadcrumb>\n   <li><router-link to="/">Home</router-link><span class="vs-breadcrum--separator">/</span></li>\n   <li><router-link to="/apps/chat">Chat</router-link><span class="vs-breadcrum--separator">/</span></li>\n   <li aria-current="page" class="active">Infos</li>\n</vs-breadcrumb>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }