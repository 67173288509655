var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Default", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Use the "),
        _c("code", [_vm._v("items")]),
        _vm._v(" in order to programatically generate the breadcrumb links"),
      ]),
      _c(
        "div",
        { staticClass: "demo-alignment" },
        [
          _c("vs-breadcrumb", {
            attrs: {
              items: [
                { title: "Dashboard", url: "/" },
                { title: "Link 1", url: "/blog" },
                { title: "Link 2", disabled: true },
                { title: "Active", active: true },
              ],
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<vs-breadcrumb\n:items=\"\n   [\n     {\n       title: 'Dashboard',\n       url: '/'\n     },\n     {\n       title: 'Link 1',\n       url: '/blog'\n     },\n     {\n       title: 'Link 2',\n       disabled: true\n     },\n     {\n       title: 'Active',\n       active: true\n     }\n   ]\"\n></vs-breadcrumb>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }