var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Alignment", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Use "),
        _c("code", [_vm._v("align")]),
        _vm._v(" to either align the breadcrumb to the left, center or right"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("vs-breadcrumb", {
            staticClass: "op-block mb-2",
            attrs: { items: _vm.items, align: "left" },
          }),
          _c("vs-breadcrumb", {
            staticClass: "op-block mb-2",
            attrs: { items: _vm.items, align: "center" },
          }),
          _c("vs-breadcrumb", {
            staticClass: "op-block mb-2",
            attrs: { items: _vm.items, align: "right" },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-breadcrumb align="left">...</vs-breadcrumb>\n<vs-breadcrumb align="center">...</vs-breadcrumb>\n<vs-breadcrumb align="right">...</vs-breadcrumb>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }