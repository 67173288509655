var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Separator", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v(
          "You can change the separator dividing the links by either passing a string to the "
        ),
        _c("code", [_vm._v("separator")]),
        _vm._v(' directive like so "'),
        _c("strong", [_vm._v("•")]),
        _vm._v('", "'),
        _c("strong", [_vm._v("-")]),
        _vm._v('", "'),
        _c("strong", [_vm._v("|")]),
        _vm._v('" etc..., or by passing in one of the material icons text "'),
        _c("strong", [_vm._v("chevron_right")]),
        _vm._v('"'),
      ]),
      _c(
        "vs-alert",
        {
          staticClass: "mt-5",
          attrs: { icon: "warning", active: "true", color: "warning" },
        },
        [
          _c("span", [
            _vm._v('In order to differeciate a custom separator "'),
            _c("strong", [_vm._v("•")]),
            _vm._v('" from an icon "'),
            _c("strong", [_vm._v("chevron_right")]),
            _vm._v('", we verify the string length. If the length is '),
            _c("strong", [_vm._v("greater than 1")]),
            _vm._v(" it will be considered a material icon."),
          ]),
        ]
      ),
      _c("br"),
      _c(
        "div",
        [
          _c("vs-breadcrumb", { attrs: { items: _vm.items, separator: "•" } }),
          _c("vs-breadcrumb", { attrs: { items: _vm.items, separator: "-" } }),
          _c("vs-breadcrumb", { attrs: { items: _vm.items, separator: "|" } }),
          _c("vs-breadcrumb", {
            attrs: {
              items: _vm.items,
              separator: _vm.$vs.rtl ? "chevron_left" : "chevron_right",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<vs-breadcrumb :items="[{...}, {...}]" separator="•"></vs-breadcrumb>\n<vs-breadcrumb :items="[{...}, {...}]" separator="-"></vs-breadcrumb>\n<vs-breadcrumb :items="[{...}, {...}]" separator="|"></vs-breadcrumb>\n<vs-breadcrumb :items="[{...}, {...}]" :separator="$vs.rtl ? \'chevron_left\' : \'chevron_right\'"</vs-breadcrumb>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }